import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import {
  HiDesktopComputer,
  HiEye,
  HiOutlineDesktopComputer,
  HiOutlineDeviceMobile,
} from "react-icons/hi";
import { VscFilePdf, VscFileZip, VscFile } from "react-icons/vsc";
import Dialog from "../Dialog";
import Alert from "../Alert";
import useWindowSize from "../hooks/useWindowSize";
import { openWebShareModal, setShareItem } from "store/commonReducer";
import { useDispatch } from "react-redux";

const BYTE = 1000;
const getKB = (bytes) => Math.round(bytes / BYTE);

const FileIcon = ({ children }) => {
  return <span className="text-4xl">{children}</span>;
};

const FileItem = (props) => {
  const {
    files,
    index,
    file,
    children,
    desktopWidth,
    desktopHeight,
    mobileWidth,
    mobileHeight,
    actualDesktopWidth,
    actualDesktopHeight,
    actualMobileWidth,
    actualMobileHeight,
    typeOf,
    isFormatName,
  } = props;
  const dispatch = useDispatch();
  const { type, name, size } = file;

  const [image, setImage] = useState(URL.createObjectURL(file));

  const renderThumbnail = () => {
    if (!type) {
      return null; // or handle the case where type is undefined
    }

    const isImageFile = type.split("/")[0] === "image";

    if (isImageFile) {
      return (
        <div className="relative">
          <img
            className="upload-file-image"
            src={image}
            alt={`file preview ${name}`}
          />
          <div className="upload-file-preview">
            <Icon icon="lucide:eye" />
          </div>
        </div>
      );
    }

    if (type === "application/zip") {
      return (
        <FileIcon>
          <VscFileZip />
        </FileIcon>
      );
    }

    if (type === "application/pdf") {
      return (
        <FileIcon>
          <VscFilePdf className={`vsc-pdf-icon show`} />
        </FileIcon>
      );
    }

    return (
      <FileIcon>
        <VscFile className={`vsc-pdf-icon show`} />
      </FileIcon>
    );
  };

  const [selectedImg, setSelectedImg] = useState({});
  const [viewOpen, setViewOpen] = useState(false);
  const [reminder, setReminder] = useState(false);

  const onViewOpen = (img, type) => {
    loadImageDimensions(img);

    setSelectedImg({
      name: file.name,
      img: img,
      type: type,
    });
    setViewOpen(true);
  };

  const onDialogClose = () => {
    setViewOpen(false);
    setTimeout(() => {
      setSelectedImg({});
    }, 300);
  };

  const [imageWidth, setImageWidth] = useState(null);
  const [imageHeight, setImageHeight] = useState(null);

  const loadImageDimensions = (imageSrc) => {
    const img = new Image();
    img.src = imageSrc;

    img.onload = function () {
      setImageWidth(img.width);
      setImageHeight(img.height);
    };

    if (imageWidth / imageHeight !== actualDesktopWidth / actualDesktopHeight) {
      setReminder(true);
    }
  };

  useEffect(() => {
    loadImageDimensions(image);
  }, []);

  const { width, height } = useWindowSize();
  const screen_width = width;

  const styleForSmallScreen = {
    display: "none",
  };

  const styleForLargeScreen = {
    width: `${desktopWidth}px`,
    height: `${desktopHeight}px`,
    minWidth: `${desktopWidth}px`,
    minHeight: `${desktopHeight}px`,
    overflow: "hidden",
  };

  const styleForSmallScreenMobile = {
    width: "90px",
    height: "60px",
  };

  const styleForLargeScreenMobile = {
    width: `${mobileWidth}px`,
    height: `${mobileHeight}px`,
    minWidth: `${mobileWidth}px`,
    minHeight: `${mobileHeight}px`,
    overflow: "hidden",
  };

  function formatName(address, length = "short") {
    if (address && length === "short") {
      address =
        address.substring(0, 3) +
        "..." +
        address.substring(address.length - 3, address.length);
      return address;
    } else {
      return null;
    }
  }

  return (
    <>
      {typeOf === "image.file.new" ? (
        <>
          <div className="upload-file">
            <div className="w-full">
              <div className="p-3">
                {reminder && (
                  <Alert showIcon className="mb-4">
                    Uploaded image size: {imageWidth} x {imageHeight}.
                    <br />
                    For optimal display, consider uploading images that match
                    these dimensions.
                  </Alert>
                )}
              </div>
              <div className="flex w-full">
                <div className="flex pointer" style={{ width: "80%" }}>
                  <div
                    className="flex items-center p-3 gap-3"
                    style={{ overflow: "auto" }}
                  >
                    <div
                      className="upload-file-thumbnail group flex relative p-0"
                      onClick={() => {
                        const fileList = [...files];

                        const clickedFile = fileList.splice(index, 1)[0];
                        fileList.unshift(clickedFile);

                        dispatch(
                          setShareItem({
                            fileList: fileList,
                          })
                        );
                        dispatch(openWebShareModal());
                      }}
                      // style={{
                      //   width: `${desktopWidth}px`,
                      //   height: `${desktopHeight}px`,
                      // }}
                      style={
                        screen_width < 991
                          ? styleForSmallScreen
                          : styleForLargeScreen
                      }
                    >
                      {renderThumbnail()}
                      <div className="absolute inset-0 bg-gray-900/[.7] group-hover:flex hidden text-xl items-center justify-center">
                        <span
                          onClick={() => onViewOpen(image, "desktop")}
                          className="text-gray-100 hover:text-gray-300 cursor-pointer p-1"
                        >
                          <Icon icon="ion:desktop-outline" />
                        </span>
                        <span
                          onClick={() => onViewOpen(image, "desktop")}
                          className="text-gray-100 hover:text-gray-300 cursor-pointer p-1"
                        >
                          <HiEye />
                        </span>
                      </div>
                    </div>
                    <div
                      className="upload-file-thumbnail group flex relative p-0"
                      // style={{
                      //   width: `${mobileWidth}px`,
                      //   height: `${mobileHeight}px`,
                      // }}
                      style={
                        screen_width < 991
                          ? styleForSmallScreenMobile
                          : styleForLargeScreenMobile
                      }
                    >
                      {renderThumbnail()}
                      <div className="absolute inset-0 bg-gray-900/[.7] group-hover:flex hidden text-xl items-center justify-center">
                        <span
                          onClick={() => onViewOpen(image, "mobile")}
                          className="text-gray-100 hover:text-gray-300 cursor-pointer p-0.5"
                        >
                          <HiOutlineDeviceMobile />
                        </span>
                        <span
                          onClick={() => onViewOpen(image, "mobile")}
                          className="text-gray-100 hover:text-gray-300 cursor-pointer p-1"
                        >
                          <HiEye />
                        </span>
                      </div>
                    </div>
                    <div className="upload-file-info">
                      {screen_width > 1100 ? (
                        // {formatName(item?.url?.split("/").pop())}
                        <h6 className="upload-file-name">
                          {isFormatName ? formatName(name) : name}
                        </h6>
                      ) : (
                        <b>
                          <p className="upload-file-name">{formatName(name)}</p>
                        </b>
                      )}
                      <span className="upload-file-size">{getKB(size)} kb</span>
                    </div>
                  </div>
                </div>
                <div
                  className="flex justify-end items-center"
                  style={{ width: "20%" }}
                >
                  {children}
                </div>
              </div>
            </div>
          </div>
          <Dialog
            isOpen={viewOpen}
            onClose={onDialogClose}
            onRequestClose={onDialogClose}
            contentClassName="preview-dialog"
            afterOpenClassName="preview-dialog-open"
          >
            <h5 className="mb-1">{selectedImg.name}</h5>
            {reminder && (
              <span style={{ color: "#ef4444" }}>
                Recommended size:{" "}
                {selectedImg.type === "desktop"
                  ? actualDesktopWidth
                    ? actualDesktopWidth
                    : desktopWidth
                  : actualMobileWidth
                    ? actualMobileWidth
                    : mobileWidth}
                &nbsp;x{" "}
                {selectedImg.type === "desktop"
                  ? actualDesktopHeight
                    ? actualDesktopHeight
                    : desktopHeight
                  : actualMobileHeight
                    ? actualMobileHeight
                    : mobileHeight}
                . Uploaded image size: {imageWidth} x {imageHeight}.
                <br />
                For optimal display, consider uploading images that match these
                dimensions.
              </span>
            )}
            <div className="mb-3" />
            <div
              style={{
                width: `${
                  screen_width < 991
                    ? "auto"
                    : selectedImg.type === "desktop"
                      ? actualDesktopWidth
                        ? actualDesktopWidth
                        : desktopWidth
                      : actualMobileWidth
                        ? actualMobileWidth
                        : mobileWidth
                }${screen_width >= 991 ? "px" : ""}`,
                height: `${
                  selectedImg.type === "desktop"
                    ? actualDesktopHeight
                      ? actualDesktopHeight
                      : desktopHeight
                    : actualMobileHeight
                      ? actualMobileHeight
                      : mobileHeight
                }px`,
              }}
            >
              <img
                className="upload-file-image"
                src={selectedImg.img}
                alt="..."
              />
            </div>
          </Dialog>
        </>
      ) : (
        <div className="upload-file">
          <div
            className="flex pointer"
            style={{
              alignItems: "center",
              justifyContent: "center",
              paddingLeft: "1rem",
              overflow: "auto",
            }}
            onClick={() => {
              const fileList = [...files];

              const clickedFile = fileList.splice(index, 1)[0];
              fileList.unshift(clickedFile);

              dispatch(
                setShareItem({
                  fileList: fileList,
                })
              );
              dispatch(openWebShareModal());
            }}
          >
            <div className="upload-file-thumbnail">{renderThumbnail()}</div>
            <div className="upload-file-info">
              {/* <h6 className="upload-file-name">{name}</h6> */}
              {screen_width > 1100 ? (
                <h6 className="upload-file-name">
                  {isFormatName ? formatName(name) : name}
                </h6>
              ) : (
                <b>
                  <p className="upload-file-name">{formatName(name)}</p>
                </b>
              )}
              <span className="upload-file-size">{getKB(size)} kb</span>
            </div>
          </div>
          {children}
        </div>
      )}
    </>
  );
};

export default FileItem;
