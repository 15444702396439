import React, { useEffect, useState } from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
  Table,
  Tspan,
} from "@react-pdf/renderer";
import FadasonLogo from "./../../assets/images/fadason-logo.jpg";
import SalesCar from "./../../assets/images/sales-car.jpg";
import Signature from "./../../assets/images/signture.png";

import HeaderCommission from "./components/header.commission";
import TotalCommission from "./components/total.commission";
import moment from "moment";

import InterSemiBold from "./fonts/Inter-SemiBold.ttf";
import { formatNumber } from "helper/return.number";

// Font.register({
//   family: "Oswald",
//   src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
// });

// Font.register({
//   family: "SimSun-Bold",
//   src: require("./fonts/SIMSUN.ttf"),
// });

// Font.register({
//   family: "Inter",
//   src: Inter,
// });

// Font.register({
//   family: "Inter-Bold",
//   src: require("./fonts/Inter-Bold.ttf"),
// });

Font.register({
  family: "Inter-SemiBold",
  src: InterSemiBold,
});

const styles = StyleSheet.create({
  body: {
    paddingTop: 30,
    paddingBottom: 35,
    paddingHorizontal: 35,
  },
  mainView: {
    paddingLeft: 10,
    paddingRight: 10,
  },
  header: {
    fontSize: 10,
    marginLeft: -15,
    marginBottom: 8,
    textDecoration: "underline",
    // fontFamily: "SimSun-Bold",
    textAlign: "left",
    color: "blue",
  },
  topContainer: {
    display: "flex",
    marginTop: 8,
    flexDirection: "row",
    justifyContent: "center",
    border: "1px solid black",
    borderBottom: "none",
  },
  topfirstContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    borderBottom: "1px solid black",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  blue_line: {
    display: "flex",
    justifyContent: "center",
    alignSelf: "center",
    width: "110%",
    padding: 3,
  },
  firstContainer: {
    display: "flex",
    marginTop: 8,
    flexDirection: "row",
    justifyContent: "center",
    border: "1px solid black",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignSelf: "end",
  },
  firstSubContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    borderRight: "1px solid black",
    borderLeft: "1px solid black",
    borderBottom: "1px solid black",
  },
  firstContainerText: {
    fontSize: 8,
    fontWeight: 100,
    //transform: [{ scaleX: 30 }],
    // fontFamily: "Inter",
  },
  firstContainerSubText: {
    fontSize: 6,
    marginLeft: 2,
    fontWeight: 100,
    //transform: [{ scaleX: 30 }],
    // fontFamily: "Inter",
  },
  descriptionContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  grandRowContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    border: "1px solid black",
    marginTop: 8,
  },
  balanceRowContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    border: "1px solid black",
  },
  bottomContainerText: {
    marginTop: 0,
    marginBottom: 3,
    marginLeft: 2,
    fontSize: 6,
    // fontFamily: "Inter",
  },

  bottomSecondText: {
    fontSize: 6,
    marginLeft: 2,
    marginTop: 5,
    // fontFamily: "Inter",
  },
  submitText: {
    fontSize: 6,
    // fontFamily: "Inter",
    marginTop: 30,
    marginBottom: 5,
  },
  dateText: {
    fontSize: 6,
    // fontFamily: "Inter",
    marginTop: 2,
  },

  firstColumnText: {
    fontSize: 6,
    marginLeft: 5,
    // fontFamily: "Inter",
  },
  rmColumnText: {
    fontSize: 6,
    marginLeft: 7,
    // fontFamily: "Inter",
  },
  priceColumnText: {
    fontSize: 6,
    marginRight: 5,
    // fontFamily: "Inter",
  },
  spanText: {
    fontSize: 6,
    color: "red",
    // fontFamily: "Inter",
  },
});

const SalesQuo = ({ data, proformaInvoice = false }) => {
  const data_shown_name = data?.ap_detail?.shown_name;

  const calculateCarPriceWithCIF = (uobRate, currency) => {
    if (uobRate && currency) {
      const total = Number(uobRate) * Number(currency);
      const formatTotal = Number(total).toFixed(2);

      return Number(formatTotal).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    }
    return "-";
  };

  const calGrandTotal = (totalCostPrice, totalOtherCharges) => {
    // let A = totalCarPrice ? Number(totalCarPrice) : 0;
    let A = totalCostPrice ? Number(totalCostPrice) : 0;
    let B = totalOtherCharges ? Number(totalOtherCharges) : 0;
    const total = A + B;

    const formatTotal = Number(total).toFixed(2);

    if (total > 0) {
      return Number(formatTotal).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    }

    return " -";
  };

  const calculateTotalPayment = (data) => {
    let total = 0;
    data?.map((item, index) => {
      let price = item.label ? Number(item.label) : 0;
      total = total + price;
    });

    const formatTotal = Number(total).toFixed(2);
    if (total > 0) {
      return Number(formatTotal).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    }
    return " -";
  };

  const calculateTotalCharges = (data) => {
    let total = 0;
    data?.map((item, index) => {
      let price = item.detail ? Number(item.detail) : 0;
      total = total + price;
    });

    const formatTotal = Number(total).toFixed(2);
    if (total > 0) {
      return Number(formatTotal).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    }
    return " -";
  };

  const calculateServiceCharge = () => {
    let A = data?.sales_quotation?.total_car_price
      ? data.sales_quotation.total_car_price
      : 0;
    let B = data?.service_charge ? data.service_charge : 0;
    const total = A * (B / 100);

    const formatTotal = Number(total).toFixed(2);

    if (total > 0) {
      return Number(formatTotal).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    }

    return " -";
  };

  const formatCash = (currency) => {
    let cash = currency ? Number(currency).toFixed(2) : 0;
    if (cash) {
      if (cash < 0) {
        const number = Math.abs(cash);
        return `(${number.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })})`;
      } else {
        return Number(cash).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      }
    }
    return "-";
  };

  const renderChop = () => {
    switch (data_shown_name) {
      case "DCF":
        return Signature;
      case "DCFP":
        return require("../../assets/images/FP-chop.png");
      case "DCK":
        return require("../../assets/images/KR-chop.png");
      case "DCGS":
        return require("../../assets/images/GS-chop.png");
      case "DCP":
        return require("../../assets/images/DCP-chop.png");
      case "DCHO":
        return require("../../assets/images/DCHO-chop.png");
      case "DCBA":
        return require("../../assets/images/DCBA-chop.png");
      case "DCH AUTOMOBILE":
        return require("../../assets/images/DCH-chop.png");
      default:
        return Signature;
    }
  };

  const renderLogo = () => {
    switch (data_shown_name) {
      case "DCF":
        return require("../../assets/images/FDS-logo.png");
      case "DCFP":
        return require("../../assets/images/FP-logo.png");
      case "DCK":
        return require("../../assets/images/KR-logo.png");
      case "DCGS":
        return require("../../assets/images/GS-logo.JPG");
      case "DCP":
        return require("../../assets/images/DCP-Logo.jpg");
      case "DCHO":
        return require("../../assets/images/DCHO-logo.png");
      case "DCBA":
        return require("../../assets/images/DCBA-logo.png");
      case "DCH AUTOMOBILE":
        return require("../../assets/images/DCH-logo.png");
      default:
        return require("../../assets/images/FDS-logo.png");
    }
  };

  const renderBackgroundColor = () => {
    switch (data_shown_name) {
      case "DCF":
        return "#D22B2B"; //red
      case "DCFP":
        return "#FFBF00"; //orange
      case "DCK":
        return "#538DD5"; //blue
      case "DCGS":
        return "#0D6856"; //green
      case "DCP":
        return "#c3a372"; //brown
      case "DCHO":
        return "#00b8ad"; //tiffany blue
      case "DCBA":
        return "#846c8c"; //purple
      default:
        return "#D22B2B";
    }
  };

  const renderAddressAP = () => {
    switch (data_shown_name) {
      case "DCF":
        return (
          <View style={{ marginTop: 15 }}>
            <Text
              style={{
                fontSize: 8,
                textTransform: "uppercase",
              }}
            >
              NO. 1, JALAN ASTAKA 4/KU2,
            </Text>
            <Text
              style={{
                fontSize: 8,
                textTransform: "uppercase",
              }}
            >
              BANDAR BUKIT RAJA,
            </Text>
            <Text
              style={{
                fontSize: 8,
                textTransform: "uppercase",
              }}
            >
              41050 KLANG,
            </Text>
            <Text
              style={{
                fontSize: 8,
                textTransform: "uppercase",
              }}
            >
              SELANGOR DARUL EHSAN.
            </Text>
          </View>
        );
      case "DCFP":
        return (
          <View style={{ marginTop: 15 }}>
            <Text
              style={{
                fontSize: 8,
                textTransform: "uppercase",
              }}
            >
              No 3, JALAN ASTANA 1D,
            </Text>
            <Text
              style={{
                fontSize: 8,
                textTransform: "uppercase",
              }}
            >
              BANDAR BUKIT RAJA,
            </Text>
            <Text
              style={{
                fontSize: 8,
                textTransform: "uppercase",
              }}
            >
              41050 KLANG, SELANGOR
            </Text>
          </View>
        );
      case "DCK":
        return (
          <View style={{ marginTop: 15 }}>
            <Text
              style={{
                fontSize: 8,
                textTransform: "uppercase",
              }}
            >
              22A, Jalan Astana 1B,
            </Text>
            <Text
              style={{
                fontSize: 8,
                textTransform: "uppercase",
              }}
            >
              Bandar Bukit Raja,
            </Text>
            <Text
              style={{
                fontSize: 8,
                textTransform: "uppercase",
              }}
            >
              41050 Klang Selangor.
            </Text>
          </View>
        );
      case "DCGS":
        return (
          <View style={{ marginTop: 15 }}>
            <Text
              style={{
                fontSize: 8,
                textTransform: "uppercase",
              }}
            >
              No.3, Jalan Kenanga 1/1,
            </Text>
            <Text
              style={{
                fontSize: 8,
                textTransform: "uppercase",
              }}
            >
              Salak Perdana,
            </Text>
            <Text
              style={{
                fontSize: 8,
                textTransform: "uppercase",
              }}
            >
              43900 Sepang ,Selangor.
            </Text>
          </View>
        );
      case "DCP":
        return (
          <View style={{ marginTop: 15 }}>
            <Text
              style={{
                fontSize: 8,
                textTransform: "uppercase",
              }}
            >
              No.6, Jalan Astana 1B,
            </Text>
            <Text
              style={{
                fontSize: 8,
                textTransform: "uppercase",
              }}
            >
              Bandar Bukit Raja,
            </Text>
            <Text
              style={{
                fontSize: 8,
                textTransform: "uppercase",
              }}
            >
              41050 Klang ,Selangor.
            </Text>
          </View>
        );
      case "DCHO":
        return (
          <View style={{ marginTop: 15 }}>
            <Text
              style={{
                fontSize: 8,
                textTransform: "uppercase",
              }}
            >
              27, Jalan Pemberita U1/49,
            </Text>
            <Text
              style={{
                fontSize: 7,
                textTransform: "uppercase",
              }}
            >
              Hicom-glenmarie Industrial Park,
            </Text>
            <Text
              style={{
                fontSize: 7,
                textTransform: "uppercase",
              }}
            >
              40150 Shah Alam, Selangor.
            </Text>
          </View>
        );
      case "DCBA":
        return (
          <View style={{ marginTop: 15 }}>
            <Text
              style={{
                fontSize: 8,
                textTransform: "uppercase",
              }}
            >
              NO.18, 1ST FLOOR JALAN KIDAMAI 2/1,
            </Text>
            <Text
              style={{
                fontSize: 7,
                textTransform: "uppercase",
              }}
            >
              TAMAN PERINDUSTRIAN KIDAMAI 2,
            </Text>
            <Text
              style={{
                fontSize: 7,
                textTransform: "uppercase",
              }}
            >
              43000 KAJANG SELANGOR.
            </Text>
          </View>
        );
      case "DCH AUTOMOBILE":
        return (
          <View style={{ marginTop: 15 }}>
            <Text
              style={{
                fontSize: 8,
                textTransform: "uppercase",
              }}
            >
              No.7, Jalan Astana 1F/KU2,
            </Text>
            <Text
              style={{
                fontSize: 8,
                textTransform: "uppercase",
              }}
            >
              Bandar Bukit Raja,
            </Text>
            <Text
              style={{
                fontSize: 8,
                textTransform: "uppercase",
              }}
            >
              41050 Klang ,Selangor.
            </Text>
          </View>
        );
      default:
        return (
          <View style={{ marginTop: 15 }}>
            <Text
              style={{
                fontSize: 8,
                textTransform: "uppercase",
              }}
            >
              NO. 1, JALAN ASTAKA 4/KU2,
            </Text>
            <Text
              style={{
                fontSize: 8,
                textTransform: "uppercase",
              }}
            >
              BANDAR BUKIT RAJA,
            </Text>
            <Text
              style={{
                fontSize: 8,
                textTransform: "uppercase",
              }}
            >
              41050 KLANG,
            </Text>
            <Text
              style={{
                fontSize: 8,
                textTransform: "uppercase",
              }}
            >
              SELANGOR DARUL EHSAN.
            </Text>
          </View>
        );
    }
  };

  const renderBank = () => {
    switch (data?.ap_id) {
      // case 2:
      //   return "STANDART CHARTERED BANK";
      default:
        return "UOB BANK";
    }
  };

  const originalPayments = data?.sales_quotation?.total_payment || [];

  const paymentsArray = [...originalPayments];
  while (paymentsArray.length < 4) {
    paymentsArray.push({}); // Push empty objects to serve as placeholders
  }

  const service_charge_is_0 = data.service_charge === "0.00";

  const formatNewLine = (remark) => {
    // Create a temporary HTML element to parse the rich text
    const tempElement = document.createElement("div");
    tempElement.innerHTML = remark; // Set the rich text as the inner HTML

    // Extract plain text and remove any newlines
    return tempElement.textContent.replace(/\s+/g, " ").trim();
  };

  const checkFirstCarPrice = () => {
    if (
      data?.sales_quotation?.advance_rate &&
      data?.sales_quotation?.advanceRateFields?.length > 0
    ) {
      let totalAmount =
        data.sales_quotation.advanceRateFields.reduce(
          (sum, field) => sum + (field.calculated_amount || 0),
          0
        ) +
        (parseFloat(data.sales_quotation.payable_custom_duty) || 0) +
        (parseFloat(data.sales_quotation.misc) || 0);

      return formatCash(totalAmount);
    }

    return formatCash(data?.sales_quotation?.total_car_price);
  };

  const checkSecCarPrice = () => {
    if (
      data?.sales_quotation?.advance_rate &&
      data?.sales_quotation?.advanceRateFields?.length > 0
    ) {
      let A = data?.sales_quotation?.total_car_price
        ? data.sales_quotation.total_car_price
        : 0;
      let B = data?.service_charge ? data.service_charge : 0;

      let service_charge = A * (B / 100);
      service_charge = Math.max(service_charge, 0); // Ensure it's not negative

      let totalAmount =
        data.sales_quotation.advanceRateFields.reduce(
          (sum, field) => sum + (field.calculated_amount || 0),
          0
        ) +
        (parseFloat(data.sales_quotation.payable_custom_duty) || 0) +
        (parseFloat(data.sales_quotation.misc) || 0) +
        service_charge;

      return formatCash(totalAmount); // Apply formatCash before returning
    }

    return data?.sales_quotation?.total_cost_price
      ? formatCash(data.sales_quotation.total_cost_price)
      : "-";
  };

  // const [imageSrc, setImageSrc] = useState(null);

  // const convertImageToBase64 = async (url) => {
  //   try {
  //     const response = await fetch(url);
  //     const blob = await response.blob();
  //     const reader = new FileReader();

  //     return new Promise((resolve, reject) => {
  //       reader.onloadend = () => resolve(reader.result);
  //       reader.onerror = () => reject("Error reading the image data.");
  //       reader.readAsDataURL(blob);
  //     });
  //   } catch (error) {
  //     console.error("Error converting image to Base64:", error);
  //     throw error; // You can handle the error later or simply log it
  //   }
  // };

  // useEffect(() => {
  //   if (data?.api_url) {
  //     convertImageToBase64(data.api_url).then((base64Image) => {
  //       // Use the base64Image in your PDF rendering or in your component
  //       setImageSrc(base64Image); // Assuming you have state to store imageSrc
  //     });
  //   }
  // }, [data?.api_url]);

  // useEffect(() => {
  //   // Only run the fetch if the api_url exists
  //   if (data?.api_url) {
  //     fetch(data.api_url)
  //       .then((response) => {
  //         const contentType = response.headers.get("Content-Type");

  //         // Check if the content type indicates a valid image
  //         if (contentType && contentType.startsWith("image/")) {
  //         } else {
  //           console.error("This is not an image or has an unsupported format.");
  //         }
  //       })
  //       .catch((error) => {
  //         console.error("Error fetching the image:", error);
  //       });
  //   }
  // }, [data?.api_url]);

  return (
    <Document>
      <Page style={styles.body} pageLayout="twoColumnLeft">
        <View style={styles.mainView}>
          <View style={[{ display: "grid", height: "40" }]}>
            <View style={[{ position: "relative" }]}>
              <Image
                style={{
                  width:
                    data_shown_name == "DCK"
                      ? "60"
                      : data_shown_name == "DCFP" || data_shown_name == "DCBA"
                        ? "70"
                        : data_shown_name == "DCHO"
                          ? "150"
                          : data_shown_name == "DCH AUTOMOBILE"
                            ? "110"
                            : "50",
                  right: 0,
                  top:
                    data_shown_name == "DCP" || data_shown_name == "DCHO"
                      ? -10
                      : data_shown_name == "DCBA"
                        ? -20
                        : 0,
                  position: "absolute",
                }}
                src={renderLogo()}
              />
            </View>
            <View style={[{ position: "relative", height: "38" }]}>
              <Text
                style={{
                  fontSize: 10,
                  fontFamily: "Inter-SemiBold",
                  marginTop: 1,
                  right: 0,
                  bottom: 0,
                  position: "absolute",
                }}
              >
                {data?.ap_detail?.name}
              </Text>
            </View>
          </View>
          <div
            style={[
              styles.blue_line,
              { backgroundColor: renderBackgroundColor() },
            ]}
          ></div>

          <div>
            <View style={{ flexDirection: "row", marginTop: 8 }}>
              <View style={{ flex: 7 }}>
                <View>
                  <Text
                    style={{
                      fontSize: 10,
                      fontFamily: "Inter-SemiBold",
                    }}
                  >
                    {proformaInvoice == true
                      ? "PROFORMA INVOICE"
                      : "QUOTATION / SALES ORDER"}
                  </Text>
                  <Text
                    style={{
                      fontSize: 10,
                      fontFamily: "Inter-SemiBold",
                    }}
                  >
                    {data?.vehicle_year} {data?.vehicle_make}{" "}
                    {data?.vehicle_model}
                  </Text>
                  <Text
                    style={{
                      fontSize: 10,
                      fontFamily: "Inter-SemiBold",
                    }}
                  >
                    CHASSIS NO : {data?.vehicle_chasis_no}
                  </Text>
                  {data?.number_plate && (
                    <Text
                      style={{
                        fontSize: 10,
                        fontFamily: "Inter-SemiBold",
                      }}
                    >
                      NUMBER PLATE : {data?.number_plate}
                    </Text>
                  )}
                </View>
                <View
                  style={{
                    marginTop: 6,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    height: 75,
                    gap: 20,
                  }}
                >
                  <View style={{ flex: 6, padding: 0.5 }}>
                    <Text
                      style={{
                        fontSize: 8, // fontFamily: "Inter-SemiBold"
                      }}
                    >
                      FROM :
                    </Text>
                    <Text
                      style={{
                        fontSize: 8,
                        fontFamily: "Inter-SemiBold",
                      }}
                    >
                      {data?.ap_detail?.name}
                    </Text>
                    {/* <Text
                      style={{
                        fontSize: 8,
                        marginTop: 15,
                        textTransform: "uppercase",
                      }}
                    >
                      {data?.ap_detail?.address}
                    </Text> */}
                    {renderAddressAP()}
                  </View>
                  <View
                    style={{
                      flex: 6,
                      // borderRight: "1px solid black",
                      padding: 0.5,
                      width: 50,
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 8, // fontFamily: "Inter-SemiBold"
                      }}
                    >
                      TO :
                    </Text>
                    <Text
                      style={{
                        fontSize: 8,
                        fontFamily: "Inter-SemiBold",
                        marginTop: 1,
                      }}
                    >
                      {data?.personal_name}
                    </Text>
                    <Text
                      style={{
                        fontSize: 8,
                        fontFamily: "Inter-SemiBold",
                        marginTop: 8,
                      }}
                    >
                      {data?.type === "company" ? "REG NO" : "IC/NO"} :{" "}
                      {data?.identity_no}
                    </Text>
                    <Text
                      style={{
                        marginTop: 8,
                        fontSize: 8,
                        fontFamily: "Inter-SemiBold",
                      }}
                    >
                      TEL : {data?.personal_contact}
                    </Text>
                    <Text
                      style={{
                        marginTop: 5,
                        fontSize: 8,
                        fontFamily: "Inter-SemiBold",
                      }}
                    >
                      {/* {data?.address ?? ""} */}
                      {/* {`${data?.address ?? ""}\n${data?.postcode ?? ""}\n${data?.city ?? ""}\n${data?.state ?? ""}`} */}
                      {data?.address ?? ""} {data?.postcode ?? ""}{" "}
                      {data?.city ?? ""} {data?.state ?? ""}
                    </Text>
                  </View>
                </View>
                <View>
                  <Text style={{ fontSize: 8, marginTop: 5 }}>
                    <Text
                      style={{
                        textDecoration: "underline",
                        // fontFamily: "Inter",
                      }}
                    >
                      ~Updated on :
                    </Text>
                    <Text
                      style={{
                        fontFamily: "Inter-SemiBold",
                      }}
                    >
                      {" "}
                      {data?.sales_quotation?.date
                        ? moment(data?.sales_quotation?.date).format(
                            "dddd,DD MMM,YYYY"
                          )
                        : new Date().toLocaleDateString()}
                    </Text>
                  </Text>
                  <Text
                    style={{
                      fontSize: 8,
                      marginBottom: 2,
                      // fontFamily: "Inter",
                      marginTop: 1,
                    }}
                  >
                    We are pleased to quote you the following:
                  </Text>
                </View>
              </View>
              <View
                style={{
                  flex: 4,
                  height: "150",
                  width: "150",
                  // alignSelf: "center",
                }}
              >
                {data?.api_url && (
                  <Image
                    style={{
                      objectFit: "cover",
                      // width: "100%",
                      // height: "auto",
                      // marginLeft: "auto",
                    }}
                    src={data?.api_url}
                  />
                )}
              </View>
            </View>

            {/* <View
              style={[
                styles.topContainer,
                { borderBottom: "1px solid black", gap: 20 },
              ]}
            >
              <View style={{ flex: 8, borderRight: "1px solid black" }}>
                <HeaderCommission
                  data={data}
                  title1="QUOTATION / SALES ORDER"
                  type="quoHeader"
                />
                <View style={styles.topfirstContainer}>
                  <View
                    style={{
                      flex: 14,
                      padding: 0.5,
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 14, // fontFamily: "Inter-SemiBold"
                      }}
                    >
                      {data?.vehicle_year} {data?.vehicle_make}{" "}
                      {data?.vehicle_model}
                    </Text>
                    <Text
                      style={{
                        fontSize: 14,
                        // fontFamily: "Inter-SemiBold",
                        marginTop: 1,
                      }}
                    >
                      CHASSIS NO. : {data?.vehicle_chasis_no}
                    </Text>
                    <Text
                      style={{
                        fontSize: 14,
                        // fontFamily: "Inter-SemiBold",
                        marginTop: 1,
                        marginBottom: 1,
                      }}
                    >
                      NUMBER PLATE : {data?.number_plate}
                    </Text>
                  </View>
                </View>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    height: 70,
                  }}
                >
                  <View style={{ flex: 6, padding: 0.5 }}>
                    <Text
                      style={{
                        fontSize: 8, // fontFamily: "Inter-SemiBold"
                      }}
                    >
                      FROM :
                    </Text>
                    <Text
                      style={{
                        fontSize: 8,
                        marginTop: 15,
                        // fontFamily: "Inter-SemiBold",
                      }}
                    >
                      {data?.ap_detail?.name}
                    </Text>
                    <Text
                      style={{
                        fontSize: 8,
                        marginTop: 15,
                        // fontFamily: "Inter",
                      }}
                    >
                      {data?.ap_detail?.address}
                    </Text>
                  </View>
                  <View
                    style={{
                      flex: 6,
                      // borderRight: "1px solid black",
                      padding: 0.5,
                      width: 50,
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 8, // fontFamily: "Inter-SemiBold"
                      }}
                    >
                      TO :
                    </Text>
                    <Text
                      style={{
                        fontSize: 8,
                        marginTop: 3,
                        // fontFamily: "Inter-SemiBold",
                      }}
                    >
                      {data?.personal_name}
                    </Text>
                    <Text
                      style={{
                        fontSize: 8,
                        marginTop: 3,
                        // fontFamily: "Inter-SemiBold",
                        marginTop: 1,
                      }}
                    >
                      ({data?.identity_no})
                    </Text>
                    <Text
                      style={{
                        fontSize: 8,
                        // fontFamily: "Inter-SemiBold",
                        marginTop: 1,
                      }}
                    >
                      H/P : {data?.personal_contact}
                    </Text>
                    <Text style={styles.firstContainerText}>
                      {data?.address}
                    </Text>
                  </View>
                </View>
                <Text style={{ fontSize: 8, marginTop: 20 }}>
                  <Text
                    style={{
                      textDecoration: "underline",
                      // fontFamily: "Inter",
                    }}
                  >
                    ~Updated on :
                  </Text>
                  <Text
                    style={
                      {
                        // fontFamily: "Inter-SemiBold"
                      }
                    }
                  >
                    {" "}
                    {data?.sales_quotation?.date
                      ? new Date(data.sales_quotation.date).toLocaleDateString()
                      : new Date().toLocaleDateString()}
                  </Text>
                </Text>
                <Text
                  style={{
                    fontSize: 8,
                    marginBottom: 2,
                    // fontFamily: "Inter",
                    marginTop: 1,
                  }}
                >
                  We are pleased to quote you the following:
                </Text>
              </View>
              <View
                style={{
                  flex: 4,
                  height: "90",
                  padding: 0.5,
                  alignSelf: "center",
                }}
              >
                {data?.api_url && (
                  <Image
                    style={{
                      width: "90%",
                      height: "auto",
                      // margin: 10,
                      // marginBottom: 15,
                    }}
                    src={data?.api_url}
                  />
                )}
              </View>
            </View> */}
          </div>

          {data?.sales_quotation?.sales_quo_remark && (
            <>
              <View
                style={[
                  {
                    marginTop: "3px",
                    // border: "1px solid black",
                  },
                ]}
              >
                <Text
                  style={{
                    fontSize: 8,
                  }}
                >
                  REMARK :{" "}
                  {formatNewLine(data?.sales_quotation?.sales_quo_remark)}
                </Text>
              </View>
            </>
          )}
          <View
            style={[
              {
                marginTop: "8px",
                borderTop: "1px solid black",
                borderRight: "1px solid black",
                borderLeft: "1px solid black",
              },
            ]}
          >
            {!service_charge_is_0 && (
              <>
                <HeaderCommission
                  data={data}
                  title1="description"
                  title2="amount"
                  type="twoCenterColumns"
                />
                <View style={styles.descriptionContainer}>
                  <View style={{ flex: 6, padding: 0.5 }}></View>
                  <View style={{ flex: 2, padding: 0.5 }}>
                    <Text
                      style={{
                        fontSize: 8,
                        marginLeft: 2, // fontFamily: "Inter"
                      }}
                    >
                      bank rate *
                    </Text>
                  </View>
                  <View style={{ flex: 2, padding: 0.5 }}>
                    <Text
                      style={{
                        fontSize: 8,
                        marginLeft: 2,
                        fontFamily: "Inter-SemiBold",
                      }}
                    >
                      GBP/JPY
                    </Text>
                  </View>
                  <View style={{ flex: 2, padding: 0.5 }}>
                    <Text
                      style={{
                        fontSize: 8,
                        marginLeft: 2,
                        textAlign: "center",
                        justifyContent: "center",
                        fontFamily: "Inter-SemiBold",
                      }}
                    >
                      RM
                    </Text>
                  </View>
                </View>

                {data?.sales_quotation?.advance_rate &&
                data?.sales_quotation?.advanceRateFields?.length > 0 ? (
                  data.sales_quotation.advanceRateFields.map((field, index) => (
                    <View style={styles.descriptionContainer} key={index}>
                      <View style={{ flex: 6, padding: 0.5 }}>
                        <Text style={styles.firstContainerText}>
                          CAR PRICE with CIF
                        </Text>
                      </View>
                      <View style={{ flex: 2, padding: 0.5 }}>
                        <Text
                          style={{
                            fontSize: 8,
                            marginLeft: "auto",
                            marginRight: "auto",
                          }}
                        >
                          {field.exchange_rate ?? 0}
                        </Text>
                      </View>
                      <View style={{ flex: 2, padding: 0.5 }}>
                        <Text
                          style={{
                            fontSize: 8,
                            marginLeft: "auto",
                            marginRight: "auto",
                          }}
                        >
                          {formatCash(field.cif_amount) ?? 0}
                        </Text>
                      </View>
                      <View style={{ flex: 1 }}>
                        <Text style={{ fontSize: 8, marginLeft: "auto" }}>
                          RM
                        </Text>
                      </View>
                      <View style={{ flex: 2 }}>
                        <Text
                          style={{
                            fontSize: 8,
                            marginLeft: "auto",
                            paddingRight: 5,
                          }}
                        >
                          {formatCash(field.calculated_amount) ?? 0}
                        </Text>
                      </View>
                    </View>
                  ))
                ) : (
                  <View style={styles.descriptionContainer}>
                    <View style={{ flex: 6, padding: 0.5 }}>
                      {data?.sales_quotation?.estimated_uob_rate ? (
                        <Text style={styles.firstContainerText}>
                          CAR PRICE with CIF (ESTIMATED)
                        </Text>
                      ) : (
                        <Text style={styles.firstContainerText}>
                          CAR PRICE with CIF
                        </Text>
                      )}
                    </View>
                    <View style={{ flex: 2, padding: 0.5 }}>
                      <Text
                        style={{
                          fontSize: 8,
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                      >
                        {data?.sales_quotation?.uob_rate ?? 0}
                      </Text>
                    </View>
                    <View style={{ flex: 2, padding: 0.5 }}>
                      <Text
                        style={{
                          fontSize: 8,
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                      >
                        {formatCash(
                          data?.sales_quotation?.car_price_with_cif
                        ) ?? 0}
                      </Text>
                    </View>
                    <View style={{ flex: 1 }}>
                      <Text style={{ fontSize: 8, marginLeft: "auto" }}>
                        RM
                      </Text>
                    </View>
                    <View style={{ flex: 2 }}>
                      <Text
                        style={{
                          fontSize: 8,
                          marginLeft: "auto",
                          paddingRight: 5,
                        }}
                      >
                        {calculateCarPriceWithCIF(
                          data?.sales_quotation?.uob_rate,
                          data?.sales_quotation?.car_price_with_cif
                        )}
                      </Text>
                    </View>
                  </View>
                )}

                {/* <View style={styles.descriptionContainer}>
                  <View style={{ flex: 6, padding: 0.5 }}>
                    {data?.sales_quotation?.estimated_uob_rate ? (
                      <Text
                        // style={{
                        //   fontSize: 8,
                        //   marginLeft: "auto",
                        //   marginRight: "auto",
                        // }}
                        style={styles.firstContainerText}
                      >
                        CAR PRICE with CIF ( ESTIMATED )
                      </Text>
                    ) : (
                      <Text style={styles.firstContainerText}>
                        CAR PRICE with CIF
                      </Text>
                    )}
                  </View>
                  <View style={{ flex: 2, padding: 0.5 }}>
                    <Text
                      style={{
                        fontSize: 8,
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                    >
                      {data?.sales_quotation?.uob_rate
                        ? data.sales_quotation.uob_rate
                        : 0}
                    </Text>
                  </View>
                  <View style={{ flex: 2, padding: 0.5 }}>
                    <Text
                      style={{
                        fontSize: 8,
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                    >
                      {data?.sales_quotation?.car_price_with_cif
                        ? formatCash(data.sales_quotation.car_price_with_cif)
                        : 0}
                    </Text>
                  </View>
                  <View style={{ flex: 1 }}>
                    <Text style={{ fontSize: 8, marginLeft: "auto" }}>RM</Text>
                  </View>
                  <View style={{ flex: 2 }}>
                    <Text
                      style={{
                        fontSize: 8,
                        marginLeft: "auto",
                        paddingRight: 5,
                      }}
                    >
                      {calculateCarPriceWithCIF(
                        data?.sales_quotation?.uob_rate,
                        data?.sales_quotation?.car_price_with_cif
                      )}
                    </Text>
                  </View>
                </View> */}

                <View style={[styles.descriptionContainer, { marginTop: 2 }]}>
                  <View style={{ flex: 6, padding: 0.5 }}>
                    <Text style={styles.firstContainerText}>
                      PAYABLE CUSTOMS DUTY **
                    </Text>
                  </View>
                  <View style={{ flex: 2, padding: 0.5 }}>
                    <Text
                      style={{
                        fontSize: 8,
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                    ></Text>
                  </View>
                  <View style={{ flex: 2, padding: 0.5 }}>
                    {data?.sales_quotation?.estimated_price && (
                      <Text
                        style={{
                          fontSize: 8,
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                      >
                        ESTIMATED
                      </Text>
                    )}
                  </View>
                  <View style={{ flex: 1 }}>
                    <Text style={{ fontSize: 8, marginLeft: "auto" }}>RM</Text>
                  </View>
                  <View style={{ flex: 2 }}>
                    <Text
                      style={{
                        fontSize: 8,
                        marginLeft: "auto",
                        paddingRight: 5,
                      }}
                    >
                      {data?.sales_quotation?.payable_custom_duty
                        ? formatCash(data.sales_quotation.payable_custom_duty)
                        : "-"}
                    </Text>
                  </View>
                </View>

                <View style={[styles.descriptionContainer, { marginTop: 2 }]}>
                  <View style={{ flex: 6, padding: 0.5 }}>
                    {!service_charge_is_0 && (
                      <Text style={{ color: "red", fontSize: 5 }}>
                        ** BUYER IS FULLY RESPONSIBLE TO PAY ANY ADDITIONAL DUTY
                        IN THE EVENT IF THERE IS ANY INCREASE IN THE FINAL DUTY
                        **
                      </Text>
                    )}
                  </View>
                </View>
                <View style={[styles.descriptionContainer, { marginTop: 2 }]}>
                  <View style={{ flex: 6, padding: 0.5 }}>
                    <Text style={styles.firstContainerText}>MISC</Text>
                  </View>
                  <View style={{ flex: 2, padding: 0.5 }}>
                    <Text style={{ fontSize: 6, marginLeft: 5 }}></Text>
                  </View>
                  <View style={{ flex: 2, padding: 0.5 }}>
                    <Text style={{ fontSize: 6, marginLeft: 8 }}></Text>
                  </View>
                  <View style={{ flex: 1 }}>
                    <Text style={{ fontSize: 8, marginLeft: "auto" }}>RM</Text>
                  </View>
                  <View style={{ flex: 2 }}>
                    <Text
                      style={{
                        fontSize: 8,
                        marginLeft: "auto",
                        paddingRight: 5,
                      }}
                    >
                      {data?.sales_quotation?.misc
                        ? formatCash(data.sales_quotation.misc)
                        : "-"}
                    </Text>
                  </View>
                </View>
              </>
            )}
            <TotalCommission
              totalTitle="car price"
              // price={formatCash(data?.sales_quotation?.total_car_price)}
              price={checkFirstCarPrice()}
            />
            {!service_charge_is_0 && (
              <>
                <View
                  style={{ height: data?.service_charge === "8.00" ? 30 : 25 }}
                >
                  {data?.service_charge !== "0.00" && (
                    <View style={styles.descriptionContainer}>
                      <View style={{ flex: 5, padding: 0.5 }}>
                        <Text style={styles.firstContainerText}>
                          SERVICE CHARGE
                        </Text>
                      </View>
                      <View style={{ flex: 3, padding: 0.5 }}>
                        <Text
                          style={{
                            fontSize: 8, // fontFamily: "Inter"
                          }}
                        >
                          {/* {data?.service_charge === "3.00"
                        ? "CASH BUYER"
                        : "LOAN BUYER"} */}
                          {data?.service_charge === "3.00"
                            ? "Cash Buyer"
                            : "Loan Buyer"}
                        </Text>
                      </View>
                      <View style={{ flex: 2, padding: 0.5 }}>
                        <Text
                          style={{
                            fontSize: 8, // fontFamily: "Inter"
                          }}
                        >
                          {data?.service_charge &&
                          data?.service_charge !== "0.00"
                            ? data?.service_charge + "%"
                            : "None"}
                        </Text>
                      </View>
                      <View style={{ flex: 1 }}>
                        <Text style={{ fontSize: 8, marginLeft: "auto" }}>
                          RM
                        </Text>
                      </View>
                      <View style={{ flex: 2 }}>
                        <Text
                          style={{
                            fontSize: 8,
                            marginLeft: "auto",
                            paddingRight: 5,
                          }}
                        >
                          {data?.sales_quotation?.total_car_price &&
                          data?.service_charge
                            ? calculateServiceCharge()
                            : "-"}
                        </Text>
                      </View>
                    </View>
                  )}
                  {data?.service_charge !== "0.00" && (
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        flex: 12,
                        padding: 0.5,
                        textAlign: "left",
                        fontSize: 6,
                        marginTop: 2,
                      }}
                    >
                      <Text>**PRICE </Text>
                      <Text
                        style={{
                          fontFamily: "Inter-SemiBold",
                          marginTop: -0.5,
                          fontSize: 6.5,
                        }}
                      >
                        {data?.service_charge === "3.00"
                          ? "EXCLUDED"
                          : "INCLUDED"}
                      </Text>
                      <Text>
                        {" "}
                        1ST TIME SERVICING, INTERIOR & EXTERIOR TOUCH UP, ONE
                        YEAR WARRANTY**
                      </Text>
                    </View>
                  )}
                  {data?.service_charge === "8.00" && (
                    <Text
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        flex: 12,
                        // padding: 0.5,
                        textAlign: "left",
                        fontSize: 6.1,
                        marginTop: -2,
                      }}
                    >
                      **One year warranty provided and fulfilled by a third
                      party, subject to the terms in the third party warranty
                      booklet issued by the warranty panel (excluding supercars
                      and EV vehicles)
                    </Text>
                  )}
                </View>
                <TotalCommission
                  totalTitle="total car price"
                  // price={
                  //   data?.sales_quotation?.total_cost_price
                  //     ? formatCash(data.sales_quotation.total_cost_price)
                  //     : "-"
                  // }
                  price={checkSecCarPrice()}
                />
              </>
            )}
          </View>

          <View
            style={[
              {
                marginTop: 8,
                borderTop: "1px solid black",
                borderRight: "1px solid black",
                borderLeft: "1px solid black",
              },
            ]}
          >
            <HeaderCommission
              data={data}
              title1="other charges"
              type="oneBlueColumns"
            />
            <View style={{ minHeight: 80 }}>
              {data?.sales_quotation?.other_charges.map((item, index) => (
                <View style={[styles.descriptionContainer]} key={index}>
                  <View style={{ flex: 5, padding: 0.5 }}>
                    <Text style={styles.firstContainerText}>
                      {item.label === "KASTAM"
                        ? "KASTAM Clearance"
                        : item.label}
                    </Text>
                  </View>
                  <View style={{ flex: 3, padding: 0.5 }}>
                    {(item.label === "KASTAM" ||
                      item.label === "KASTAM Clearance") &&
                      data?.sales_quotation?.estimated_kastam_price && (
                        <Text
                          style={{
                            fontSize: 8,
                            marginLeft: "auto",
                            marginRight: "auto",
                          }}
                        >
                          ESTIMATED
                        </Text>
                      )}
                    {item.label == "One Year Insurance" &&
                      data?.sales_quotation?.estimated_insurance_price && (
                        <Text
                          style={{
                            fontSize: 8,
                            marginLeft: "auto",
                            marginRight: "auto",
                          }}
                        >
                          ESTIMATED
                        </Text>
                      )}
                  </View>
                  <View style={{ flex: 2, padding: 0.5 }}>
                    <Text style={styles.firstContainerText}> </Text>
                  </View>
                  {item.label && (
                    <View style={{ flex: 1 }}>
                      <Text style={{ fontSize: 8, marginLeft: "auto" }}>
                        RM
                      </Text>
                    </View>
                  )}
                  <View style={{ flex: 2 }}>
                    {item.label && (
                      <Text
                        style={{
                          fontSize: 8,
                          marginLeft: "auto",
                          paddingRight: 5,
                        }}
                      >
                        {item.detail ? formatCash(item.detail) : "-"}
                      </Text>
                    )}
                  </View>
                </View>
              ))}
            </View>
            <TotalCommission
              totalTitle="total"
              price={
                data?.sales_quotation?.other_charges
                  ? calculateTotalCharges(data.sales_quotation.other_charges)
                  : ""
              }
            />
          </View>

          <View style={[styles.grandRowContainer, { marginTop: 8 }]}>
            <View style={{ flex: 10, padding: 0.5 }}>
              <Text style={styles.firstContainerText}>GRAND TOTAL</Text>
            </View>
            <View style={{ flex: 1 }}>
              <Text style={{ fontSize: 8, marginLeft: "auto" }}>RM</Text>
            </View>
            <View style={{ flex: 2 }}>
              <Text
                style={{ fontSize: 8, marginLeft: "auto", paddingRight: 5 }}
              >
                {calGrandTotal(
                  data?.sales_quotation?.total_cost_price,
                  data?.sales_quotation?.total_other_charges
                )}
              </Text>
            </View>
          </View>

          <View
            style={[
              {
                marginTop: 8,
                borderTop: "1px solid black",
                borderRight: "1px solid black",
                borderLeft: "1px solid black",
              },
            ]}
          >
            <HeaderCommission
              data={data}
              title1="payments"
              title2="remark"
              title3=""
              title4=""
              // title2="remark"
              // title3="type"
              // title4="date"
              type="fourBlueColumns"
              // type="quoHeader"
            />
            <View style={{ minHeight: "30px" }}>
              {paymentsArray.map((item, index) => (
                <View style={styles.descriptionContainer} key={index}>
                  <View style={{ flex: 2 }}>
                    <Text style={styles.firstContainerText}>
                      {index + 1}
                      {index == 0 && "st Payment"}
                      {index === 1 && (
                        <Text>
                          nd Payment{" "}
                          {/* {!service_charge_is_0 && (
                            <Text style={{ color: "red", fontSize: 7.5 }}>
                              (3 working days after successful bidding and
                              purchase)
                            </Text>
                          )} */}
                        </Text>
                      )}
                      {index === 2 && (
                        <Text>
                          rd Payment{" "}
                          {/* {!service_charge_is_0 && (
                            <Text style={{ color: "red", fontSize: 7.5 }}>
                              (once the car arrives in Malaysia Port, need to
                              clear Payment within 3 Working Days)
                            </Text>
                          )} */}
                        </Text>
                      )}
                      {index === 3 && (
                        <Text>
                          th Payment{" "}
                          {/* {!service_charge_is_0 && (
                            <Text style={{ color: "red", fontSize: 7.5 }}>
                              (collect insurance payment before Car
                              Registration)
                            </Text>
                          )} */}
                        </Text>
                      )}
                      {index >= 4 && "th Payment"}
                    </Text>
                  </View>
                  <View style={{ flex: 6 }}>
                    <Text
                      style={{
                        fontSize: 8,
                        // marginLeft: "auto",
                        // marginRight: "auto",
                        // paddingRight: 180,
                      }}
                    >
                      {item.remark}
                    </Text>
                  </View>
                  {/* <View style={{ flex: 2 }}></View>
                  <View style={{ flex: 3, padding: 0.5 }}></View> 
                  */}
                  <View style={{ flex: 1 }}>
                    <Text
                      style={{
                        fontSize: 8,
                        marginLeft: "auto",
                        marginRight: "auto",
                        paddingLeft: 48,
                      }}
                    >
                      RM
                    </Text>
                  </View>
                  <View style={{ flex: 2 }}>
                    <Text
                      style={{
                        fontSize: 8,
                        marginLeft: "auto",
                        paddingRight: 5,
                      }}
                    >
                      {item?.label ? formatCash(item.label) : "-"}
                    </Text>
                  </View>
                </View>
              ))}

              {/* ######### */}
            </View>
            <TotalCommission
              totalTitle="total"
              price={calculateTotalPayment(
                data?.sales_quotation?.total_payment
              )}
            />
          </View>

          <div>
            {/* <View>
              <View style={{ marginTop: 10 }}>
                <Text
                  style={{
                    fontSize: 8,
                    // fontFamily: "Inter",
                  }}
                >
                  BANK LOAN - {data?.bank_loan_name}{" "}
                  {data?.banker_name ? `(${data.banker_name})` : ""}
                </Text>
                <Text
                  style={{
                    textAlign: "end",
                    fontSize: 8,
                  }}
                >
                  {data?.sales_quotation?.loan_amount
                    ? ` ${formatCash(data?.sales_quotation?.loan_amount)}`
                    : ""}
                </Text>
              </View>
            </View> */}
            <View
              style={{
                marginTop: 10,
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <View style={{ flex: 10, padding: 0.5 }}>
                <Text
                  style={{
                    fontSize: 8,
                    // fontFamily: "Inter",
                  }}
                >
                  BANK LOAN - {data?.bank_loan_name}{" "}
                  {data?.banker_name ? `(${data.banker_name})` : ""}
                </Text>
              </View>
              <View style={{ flex: 1 }}>
                <Text style={{ fontSize: 8, marginLeft: "auto" }}>RM</Text>
              </View>
              <View style={{ flex: 2 }}>
                <Text
                  style={{ fontSize: 8, marginLeft: "auto", paddingRight: 5 }}
                >
                  {data?.sales_quotation?.loan_amount
                    ? `${formatCash(data?.sales_quotation?.loan_amount)}`
                    : ""}
                </Text>
              </View>
            </View>
            <View
              style={[styles.balanceRowContainer, { alignItems: "center" }]}
            >
              <View style={{ flex: 10, padding: 0.5 }}>
                <Text
                  style={[
                    styles.firstContainerText,
                    {
                      fontSize: 10,
                      fontFamily: "Inter-SemiBold",
                    },
                  ]}
                >
                  BALANCE
                </Text>
              </View>
              <View style={{ flex: 1 }}>
                <Text style={{ fontSize: 8, marginLeft: "auto" }}>RM</Text>
              </View>
              <View style={{ flex: 2 }}>
                <Text
                  style={{
                    fontSize: 8,
                    marginLeft: "auto",
                    paddingRight: 5,
                    fontFamily: "Inter-Bold",
                  }}
                >
                  {data?.sales_quotation?.total_balance
                    ? formatNumber(data.sales_quotation.total_balance)
                    : "0.00"}
                </Text>
              </View>
            </View>
          </div>

          <div>
            <View style={{ marginTop: 5 }}>
              {!service_charge_is_0 && (
                <>
                  <Text
                    style={{
                      fontSize: 5,
                      marginLeft: 2,
                      // fontFamily: "Inter-SemiBold",
                      color: "red",
                      padding: 1,
                    }}
                  >
                    ** ( 3 WORKING DAYS AFTER SUCCESSFUL BIDDING AND PURCHASE )
                  </Text>
                  <Text
                    style={{
                      fontSize: 5,
                      marginLeft: 2,
                      // fontFamily: "Inter-SemiBold",
                      color: "red",
                      padding: 1,
                    }}
                  >
                    ** ( ONCE THE CAR ARRIVES IN MALAYSIA PORT, NEED TO CLEAR
                    PAYMENT WITHIN 3 WORKING DAYS )
                  </Text>
                  <Text
                    style={{
                      fontSize: 5,
                      marginLeft: 2,
                      // fontFamily: "Inter-SemiBold",
                      color: "red",
                      padding: 1,
                    }}
                  >
                    ** ( COLLECT INSURANCE PAYMENT BEFORE CAR REGISTRATION )
                  </Text>
                </>
              )}
              <Text
                style={{
                  fontSize: 5,
                  marginLeft: 2,
                  // fontFamily: "Inter-SemiBold",
                  color: "red",
                  padding: 1,
                }}
              >
                ** PLEASE NOTE THAT THE FINAL DUTY PAYABLE IS SUBJECT TO CUSTOMS
                MALAYSIA CONFIRMATION.
              </Text>
              <Text
                style={{
                  fontSize: 5,
                  marginLeft: 2,
                  // fontFamily: "Inter-SemiBold",
                  padding: 1,
                }}
              >
                ** BUYER IS FULLY RESPONSIBLE TO PAY ANY ADDITIONAL DUTY IN THE
                EVENT IF THERE IS ANY INCREASE IN THE FINAL DUTY CONFIRMED BY
                THE CUSTOMS MALAYSIA.
              </Text>
              <Text
                style={{
                  fontSize: 5,
                  marginLeft: 2,
                  // fontFamily: "Inter-SemiBold",
                  padding: 1,
                }}
              >
                ** CURRENCY RATE BASE ON QUOTATION OR BANK TT RATE.
              </Text>
              <Text
                style={{
                  fontSize: 5,
                  marginLeft: 2,
                  // fontFamily: "Inter-SemiBold",
                  padding: 1,
                }}
              >
                ** Please note that the FIRST YEAR CAR INSURANCE is{" "}
                <Text
                  style={{
                    fontSize: 5,
                    // fontFamily: "Inter-SemiBold",
                    color: "red",
                  }}
                >
                  compulsory
                </Text>{" "}
                issue by our company.
              </Text>
              {data?.service_charge === "8.00" && (
                <Text
                  style={{
                    fontSize: 6,
                    marginLeft: 2,
                    padding: 1,
                  }}
                >
                  **One year warranty provided and fulfilled by a third party,
                  subject to the terms in the third party warranty booklet
                  issued by the warranty panel (excluding supercars and EV
                  vehicles)
                </Text>
              )}
            </View>
          </div>

          <div>
            <View style={{ marginTop: 2 }}>
              <Text
                style={{
                  fontSize: 8,
                  marginLeft: 2, // fontFamily: "Inter"
                }}
              >
                NOTE:
              </Text>
              <Text
                style={{
                  fontSize: 8,
                  marginLeft: 2, // fontFamily: "Inter"
                }}
              >
                All cheques should be crossed and made payable to
              </Text>
              <Text
                style={{
                  fontSize: 8,
                  marginLeft: 2,
                  fontFamily: "Inter-SemiBold",
                }}
              >
                {data?.ap_detail?.name}
              </Text>
            </View>
          </div>

          <div>
            <View
              style={{
                marginTop: 5,
                marginBottom: 10,
                top: -5,
              }}
            >
              <Text
                style={{
                  fontSize: 8,
                  marginLeft: 2,
                  // fontFamily: "Inter",
                  padding: 1,
                }}
              >
                Bank A/C No.
                <Text
                  style={{
                    fontSize: 8,
                    marginLeft: 2,
                    fontFamily: "Inter-SemiBold",
                  }}
                >
                  {" "}
                  : {data?.ap_detail?.bank_name} {data?.ap_detail?.bank_acc}
                </Text>
              </Text>
              <Text
                style={{
                  fontSize: 8,
                  marginLeft: 2,
                  // fontFamily: "Inter",
                  padding: 1,
                  marginBottom: 10,
                }}
              >
                Company Name
                <Text
                  style={{
                    fontSize: 8,
                    marginLeft: 2,
                    fontFamily: "Inter-SemiBold",
                  }}
                >
                  : {data?.ap_detail?.name}
                </Text>
              </Text>
              <View style={styles.row}>
                <View style={{ flex: 3 }}>
                  <Image
                    style={{
                      width:
                        data_shown_name == "DCP"
                          ? "6%"
                          : data_shown_name == "DCHO"
                            ? "20%"
                            : data_shown_name == "DCBA"
                              ? "7%"
                              : "10%",
                      left:
                        data_shown_name == "DCP"
                          ? 6
                          : data_shown_name == "DCHO"
                            ? -15
                            : data_shown_name == "DCBA"
                              ? 10
                              : 0,
                      top:
                        data_shown_name == "DCHO"
                          ? 10
                          : data_shown_name == "DCBA"
                            ? -5
                            : data_shown_name == "DCP"
                              ? -7
                              : 0,
                    }}
                    src={renderChop()}
                  />
                </View>
              </View>
              <Text
                style={{
                  fontSize: 5,
                  marginLeft: 2,
                  // fontFamily: "Inter",
                  padding: 1,
                  top: data_shown_name == "DCP" ? -10 : 0,
                }}
              >
                _____________________
              </Text>
              <Text
                style={{
                  fontSize: 8,
                  marginLeft: 2,
                  // fontFamily: "Inter",
                  // padding: 1,
                  top: data_shown_name == "DCP" ? -10 : 0,
                }}
              >
                Authorised Signature
              </Text>
            </View>
          </div>

          <div
            style={[
              styles.blue_line,
              {
                backgroundColor: renderBackgroundColor(),
                // top: data_shown_name == "DCP" ? -20 : 0,
              },
            ]}
          ></div>
        </View>
      </Page>
    </Document>
  );
};

export default SalesQuo;
