import axios from "axios";
import appConfig from "configs/app.config";
import { TOKEN_TYPE, REQUEST_HEADER_AUTH_KEY } from "constants/api.constant";
import { PERSIST_STORE_NAME } from "constants/app.constant";
import deepParseJson from "utils/deepParseJson";
import store from "../store";
import { onSignOutSuccess } from "../store/auth/sessionSlice";
// import { setOpenVersionModal } from "store/auth/userSlice";
import { useDispatch } from "react-redux";
import { setOpenVersionModal } from "../store/base/commonSlice";

const unauthorizedCode = [401];
const versionError = [410];
const maintenanceError = [503];

const BaseService = axios.create({
  timeout: 60000,
  baseURL: process.env.REACT_APP_API_URL,
});

BaseService.interceptors.request.use(
  (config) => {
    const rawPersistData = localStorage.getItem(PERSIST_STORE_NAME);
    const persistData = deepParseJson(rawPersistData);

    const accessToken = persistData.auth.session.token;
    const localToken = localStorage.getItem("token");

    if (accessToken || localToken) {
      config.headers[REQUEST_HEADER_AUTH_KEY] =
        `${TOKEN_TYPE}${accessToken || localToken}`;
    }
    config.headers["Admin-Version"] = process.env.REACT_APP_VERSION;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

BaseService.interceptors.response.use(
  (response) => response,
  (error) => {
    // const dispatch = useDispatch();
    const { response } = error;

    if (response && unauthorizedCode.includes(response.status)) {
      store.dispatch(onSignOutSuccess());
    } else if (response && versionError.includes(response.status)) {
      store.dispatch(setOpenVersionModal("version"));
    } else if (response && maintenanceError.includes(response.status)) {
      store.dispatch(setOpenVersionModal("maintenance"));
    }

    return Promise.reject(error);
  }
);

export default BaseService;
