import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  currentRouteKey: "",
  openVersionModal: null,
};

export const commonSlice = createSlice({
  name: "base/common",
  initialState,
  reducers: {
    setCurrentRouteKey: (state, action) => {
      state.currentRouteKey = action.payload;
    },
    setOpenVersionModal: (state, action) => {
      state.openVersionModal = action.payload;
    },
  },
});

export const { setCurrentRouteKey, setOpenVersionModal } = commonSlice.actions;

export default commonSlice.reducer;
