import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getNotificationApi,
  getPurchaseNotificationApi,
} from "services/TranslateServices";
import ApiService from "services/ApiService";
import commonService from "services/CommonService";

export const initialData = {
  refresh: false,
  notificationParam: [],
  purchaseNotificationParam: [],
  whatsappUser: "",
  salesNotification: 0,
  openNotificationModal: false,
  openActivityLogModal: false,
  salesNotificationList: [],
  salesActivityLogList: [],
  loading: false,
  initialized: false,
  notificationStatus: "default",
  isOpenWebShareModal: false,
  isOpenFilePreview: false,
  shareItem: {},
  previewItem: {},
};

export const getNotification = createAsyncThunk(
  "get/getNotification",
  async (payload, thunkAPI) => {
    try {
      const response = await getNotificationApi(payload);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getPurchaseNotification = createAsyncThunk(
  "get/getPurchaseNotification",
  async (payload, thunkAPI) => {
    try {
      const response = await getPurchaseNotificationApi(payload);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getSalesNotificationList = createAsyncThunk(
  "get/sales/notification_list",
  async (data, thunkAPI) => {
    try {
      const respond = await ApiService.fetchData({
        url: `/sales_notification_log?search=${data.search ?? ""}&start=${data.start ?? 0}&length=${data.length ?? 20}`,
        method: "get",
      });
      return respond;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getSalesNotification = createAsyncThunk(
  "get/sales/notification ",
  async (data, thunkAPI) => {
    try {
      const respond = await ApiService.fetchData({
        url: `/sales_notification?admin_action=${data.admin_action ?? "MANAGEMENT"}`,
        method: "get",
      });
      return respond;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getActivityLog = createAsyncThunk(
  "get/activity_log/notification ",
  async (data, thunkAPI) => {
    try {
      const respond = await ApiService.fetchData({
        url: `/sales_activity_log?search=${data.search ?? ""}&start=${data.start ?? 0}&length=${data.length ?? 20}`,
        method: "get",
      });
      return respond;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const postSalesNotificationUpdate = createAsyncThunk(
  "post/notification_log_update/notification ",
  async (data, thunkAPI) => {
    try {
      const respond = await ApiService.fetchData({
        url: `/notification_log_update`,
        method: "post",
        data,
      });
      return respond;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const postOneSignalSubscrlbe = createAsyncThunk(
  "post/one_signal/subscrlbe ",
  async (data, thunkAPI) => {
    try {
      const respond = await ApiService.fetchData({
        url: `/subscribe`,
        method: "post",
        data,
      });
      return respond;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateMediaPriority = createAsyncThunk(
  "updateMediaPriority",
  async (payload, thunkAPI) => {
    try {
      const response = await commonService.updateMediaPriority(payload);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const dataSlice = createSlice({
  name: "common/data",
  initialState: initialData,
  reducers: {
    setShareItem: (state, action) => {
      state.shareItem = action.payload;
    },
    setPreviewItem: (state, action) => {
      state.previewItem = action.payload;
    },
    openWebShareModal: (state, action) => {
      state.isOpenWebShareModal = true;
    },
    closeWebShareModal: (state, action) => {
      state.isOpenWebShareModal = false;
    },
    openFilePreview: (state, action) => {
      state.isOpenFilePreview = true;
    },
    closeFilePreview: (state, action) => {
      state.isOpenFilePreview = false;
    },
    setRefresh: (state, action) => {
      state.refresh = !state.refresh;
    },
    setWhatsappUser: (state, action) => {
      state.whatsappUser = action.payload;
    },
    setOpenNotificationModal: (state, action) => {
      state.openNotificationModal = action.payload;
    },
    setOpenActivityLogModal: (state, action) => {
      state.openActivityLogModal = action.payload;
    },
    setInitialized: (state, action) => {
      state.initialized = action.payload;
    },
    setNotificationStatus: (state, action) => {
      state.notificationStatus = action.payload;
    },
  },
  extraReducers: {
    [getNotification.fulfilled]: (state, action) => {
      state.notificationParam = action.payload.data;
    },
    [getPurchaseNotification.fulfilled]: (state, action) => {
      state.loading = false;
      state.purchaseNotificationParam = action.payload.data;
    },
    [getPurchaseNotification.pending]: (state) => {
      state.loading = true;
    },
    [getPurchaseNotification.rejected]: (state, action) => {
      state.loading = false;
    },
    [getSalesNotification.fulfilled]: (state, action) => {
      state.loading = false;
      state.salesNotification = action.payload.data.data;
    },
    [getSalesNotification.pending]: (state) => {
      state.loading = true;
    },
    [getSalesNotification.rejected]: (state, action) => {
      state.loading = false;
    },
    [getSalesNotificationList.fulfilled]: (state, action) => {
      state.loading = false;
      state.salesNotificationList = action.payload.data.data;
    },
    [getSalesNotificationList.pending]: (state) => {
      state.loading = true;
    },
    [getSalesNotificationList.rejected]: (state, action) => {
      state.loading = false;
    },
    [getActivityLog.fulfilled]: (state, action) => {
      state.loading = false;
      state.salesActivityLogList = action.payload.data.data;
    },
    [getActivityLog.pending]: (state) => {
      state.loading = true;
    },
    [getActivityLog.rejected]: (state, action) => {
      state.loading = false;
    },
    [postSalesNotificationUpdate.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [postSalesNotificationUpdate.pending]: (state) => {
      state.loading = true;
    },
    [postSalesNotificationUpdate.rejected]: (state, action) => {
      state.loading = false;
    },
    [postOneSignalSubscrlbe.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [postOneSignalSubscrlbe.pending]: (state) => {
      state.loading = true;
    },
    [postOneSignalSubscrlbe.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export const {
  openWebShareModal,
  closeWebShareModal,
  openFilePreview,
  closeFilePreview,
  setShareItem,
  setPreviewItem,
  setRefresh,
  setWhatsappUser,
  setOpenNotificationModal,
  setOpenActivityLogModal,
  setInitialized,
  setNotificationStatus,
} = dataSlice.actions;

export default dataSlice.reducer;
