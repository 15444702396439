import ApiService from "./ApiService";

async function updateMediaPriority(data) {
  return ApiService.fetchData({
    url: process.env.REACT_APP_API_URL + "/media/update_priority",
    method: "post",
    data,
  });
}

const commonService = {
  updateMediaPriority,
};

export default commonService;
