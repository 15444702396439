import React from "react";
import { Text, View, StyleSheet, Font } from "@react-pdf/renderer";
import TotalCommission from "./total.commission";
import HeaderCommission from "./header.commission";

// Font.register({
//   family: "Oswald",
//   src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
// });

// Font.register({
//   family: "Inter-Bold",
//   src: require("../fonts/Inter-Bold.ttf"),
// });

const styles = StyleSheet.create({
  descriptionContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    borderRight: "1px solid black",
    borderLeft: "1px solid black",
  },
  firstContainerText: {
    marginTop: "5px",
    fontSize: 8,
    fontWeight: 100,
    width: "100%",
    //transform: [{ scaleX: 30 }],
    // fontFamily: "Inter",
  },
  firstColumnText: {
    fontSize: 8,
    marginLeft: 5,
    // fontFamily: "Inter",
  },
  rmColumnText: {
    fontSize: 8,
    marginLeft: 7,
    // fontFamily: "Inter",
  },
  priceColumnText: {
    fontSize: 8,
    marginRight: 5,
    // fontFamily: "Inter",
  },
  spanText: {
    fontSize: 8,
    color: "red",
    // fontFamily: "Inter",
    whiteSpace: "nowrap",
  },
});

const CommissionThirdTable = ({ data }) => {
  // const calTotalCommission = (
  //   dealer,
  //   agent,
  //   broker,
  //   lost,
  //   insurance,
  //   others,
  //   insurance_dealer,
  //   insurance_broker,
  //   insurance_agent
  // ) => {
  //   let A = dealer ? Number(dealer) : 0;
  //   let B = agent ? Number(agent) : 0;
  //   let C = broker ? Number(broker) : 0;
  //   let D = lost ? Number(lost) : 0;
  //   let E = insurance ? Number(insurance) : 0;
  //   let F = others ? Number(others) : 0;
  //   let G = insurance_dealer ? Number(insurance_dealer) : 0;
  //   let H = insurance_broker ? Number(insurance_broker) : 0;
  //   let I = insurance_agent ? Number(insurance_agent) : 0;

  //   // const company_commisison = data?.car_type == "used_car" ? 0 : 100;

  //   const total = A + B + C + F + G + H + I - D;

  //   const formatTotal = Number(total.toFixed(2));

  //   if (total > 0) {
  //     return formatTotal.toLocaleString(undefined, {
  //       minimumFractionDigits: 2,
  //       maximumFractionDigits: 2,
  //     });
  //   }

  //   return " -";
  // };

  const calTotalCommission = (
    commissionFields,
    lost,
    insurance,
    insurance_dealer,
    insurance_broker,
    insurance_agent
  ) => {
    // Sum all commission amounts from commissionFields
    const totalCommissionFields = commissionFields.reduce(
      (acc, field) =>
        acc + (field.amount ? Number(Number(field.amount).toFixed(2)) : 0),
      0
    );

    let D = lost ? Number(lost) : 0;
    let E = insurance ? Number(insurance) : 0;
    let G = insurance_dealer ? Number(insurance_dealer) : 0;
    let H = insurance_broker ? Number(insurance_broker) : 0;
    let I = insurance_agent ? Number(insurance_agent) : 0;

    // const company_commisison = data?.car_type == "used_car" ? 0 : 100;

    const total = totalCommissionFields + G + H + I - D;

    const formatTotal = Number(total.toFixed(2));

    if (total > 0) {
      return formatTotal.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    }

    return " -";
  };

  const calculationIsDeduction = (amount, company_commission = 0) => {
    const total = amount - company_commission;

    const formatTotal = Number(total.toFixed(2));

    if (total > 0) {
      return formatTotal.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    }

    return " -";
  };

  const formatCash = (cash) => {
    if (cash) {
      const formatTotal = Number(Number(cash).toFixed(2));
      return formatTotal.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    }
    return "-";
  };

  function formatCommission(commission) {
    return isNaN(commission) ||
      commission == null ||
      commission === "" ||
      commission === "NaN" ||
      commission == "Infinity"
      ? 0
      : commission;
  }

  const showCompanyCommission =
    data?.commission_sub?.dealer_amount ||
    data?.commission_sub?.broker_amount ||
    data?.commission_sub?.agent_amount ||
    data?.commission_sub?.other_amount;

  return (
    <div>
      <HeaderCommission title1="total commission : " type="oneColumns" />

      {data?.commission_sub?.commissionFields?.map((field, index) => (
        <View key={index}>
          <View style={styles.descriptionContainer}>
            {/* row1 */}
            <View style={{ flex: 10, display: "flex", fontSize: 8 }}>
              <Text style={{ paddingLeft: "1px" }}>
                <Text style={{ width: "auto", color: "red" }}>
                  {formatCommission(field.percentage)} %{" "}
                </Text>
                <Text style={{ width: "auto" }}>
                  COMMISSION{" "}
                  {field.type ? `- ${field.type.toUpperCase()} ` : " "}
                </Text>
                <Text style={{ width: "auto", color: "red" }}>
                  {field.bank ? field.bank : ""}
                </Text>
                {"  "}
                <Text style={{ width: "auto", color: "red" }}>
                  {field.bank_account_no ? field.bank_account_no : ""}
                </Text>
                {"  "}
                <Text style={{ width: "auto", color: "red" }}>
                  {field.bank_holder_name ? field.bank_holder_name : ""}
                </Text>
              </Text>
            </View>
            <View style={{ flex: 1, padding: 0.5 }}>
              <Text style={[styles.rmColumnText, { marginLeft: "auto" }]}>
                RM
              </Text>
            </View>
            <View style={{ flex: 2, padding: 0.5, alignItems: "flex-end" }}>
              <Text style={styles.priceColumnText}>
                {/* {formatCash(field.amount)} */}
                {calculationIsDeduction(field.amount, field.deduction_amount)}
              </Text>
            </View>
          </View>
          {field.isDeduction && (
            <View style={styles.descriptionContainer}>
              <View style={{ flex: 10, display: "flex", fontSize: 8 }}>
                <Text style={{ paddingLeft: "1px" }}>
                  <Text style={{ width: "auto" }}>Company Incentive</Text>
                </Text>
              </View>
              <View style={{ flex: 1, padding: 0.5 }}>
                <Text style={[styles.rmColumnText, { marginLeft: "auto" }]}>
                  RM
                </Text>
              </View>
              <View style={{ flex: 2, padding: 0.5, alignItems: "flex-end" }}>
                <Text style={styles.priceColumnText}>
                  {formatCash(field.deduction_amount)}
                </Text>
              </View>
            </View>
          )}
        </View>
      ))}

      {/* //old total commission

      {data?.commission_sub?.dealer_commission &&
        data?.commission_sub?.dealer_amount && (
          <View style={styles.descriptionContainer}>
            <View style={{ flex: 10, display: "flex", fontSize: 8 }}>
              <Text style={{ paddingLeft: "1px" }}>
                <Text style={{ width: "auto", color: "red" }}>
                  {formatCommission(data?.commission_sub?.dealer_commission)} %{" "}
                </Text>
                <Text style={{ width: "auto" }}>COMMISSION - DEALER </Text>
                <Text style={{ width: "auto", color: "red" }}>
                  {data?.commission_sub?.dealer_bank
                    ? data.commission_sub.dealer_bank
                    : ""}
                </Text>
                {"  "}
                <Text style={{ width: "auto", color: "red" }}>
                  {data?.commission_sub?.dealer_bank_account_no
                    ? data.commission_sub.dealer_bank_account_no
                    : ""}
                </Text>
                {"  "}
                <Text style={{ width: "auto", color: "red" }}>
                  {data?.commission_sub?.dealer_bank_holder_name
                    ? data.commission_sub.dealer_bank_holder_name
                    : ""}
                </Text>
              </Text>
            </View>
            <View style={{ flex: 1, padding: 0.5 }}>
              <Text style={[styles.rmColumnText, { marginLeft: "auto" }]}>
                RM
              </Text>
            </View>
            <View style={{ flex: 2, padding: 0.5, alignItems: "flex-end" }}>
              <Text style={styles.priceColumnText}>
                {formatCash(data?.commission_sub?.dealer_amount)}
              </Text>
            </View>
          </View>
        )}

      {data?.commission_sub?.broker_commission &&
        data?.commission_sub?.broker_amount && (
          <View style={styles.descriptionContainer}>
            <View style={{ flex: 10, display: "flex", fontSize: 8 }}>
              <Text style={{ paddingLeft: "1px" }}>
                <Text style={{ width: "auto", color: "red" }}>
                  {formatCommission(data?.commission_sub?.broker_commission)} %{" "}
                </Text>
                <Text style={{ width: "auto" }}>COMMISSION - BROKER </Text>
                <Text style={{ width: "auto", color: "red" }}>
                  {data?.commission_sub?.broker_bank
                    ? data.commission_sub.broker_bank
                    : " "}
                </Text>
                {"  "}
                <Text style={{ width: "auto", color: "red" }}>
                  {data?.commission_sub?.broker_bank_account_no
                    ? data.commission_sub.broker_bank_account_no
                    : " "}
                </Text>
                {"  "}
                <Text style={{ width: "auto", color: "red" }}>
                  {data?.commission_sub?.broker_bank_holder_name
                    ? data.commission_sub.broker_bank_holder_name
                    : " "}
                </Text>
              </Text>
            </View>
            <View style={{ flex: 1, padding: 0.5 }}>
              <Text style={[styles.rmColumnText, { marginLeft: "auto" }]}>
                RM
              </Text>
            </View>
            <View style={{ flex: 2, padding: 0.5, alignItems: "flex-end" }}>
              <Text style={styles.priceColumnText}>
                {formatCash(data?.commission_sub?.broker_amount)}
              </Text>
            </View>
          </View>
        )}

      {data?.commission_sub?.agent_commission &&
        data?.commission_sub?.agent_amount && (
          <View style={styles.descriptionContainer}>
            <View style={{ flex: 10, display: "flex", fontSize: 8 }}>
              <Text style={{ paddingLeft: "1px" }}>
                <Text style={{ width: "auto", color: "red" }}>
                  {formatCommission(data?.commission_sub?.agent_commission)} %{" "}
                </Text>
                <Text style={{ width: "auto" }}>COMMISSION - AGENT </Text>
                <Text style={{ width: "auto", color: "red" }}>
                  {data?.commission_sub?.agent_bank
                    ? data.commission_sub.agent_bank
                    : ""}
                </Text>
                {"  "}
                <Text style={{ width: "auto", color: "red" }}>
                  {data?.commission_sub?.agent_bank_account_no
                    ? data.commission_sub.agent_bank_account_no
                    : ""}
                </Text>
                {"  "}
                <Text style={{ width: "auto", color: "red" }}>
                  {data?.commission_sub?.agent_bank_holder_name
                    ? data.commission_sub.agent_bank_holder_name
                    : ""}
                </Text>
              </Text>
            </View>
            <View style={{ flex: 1, padding: 0.5 }}>
              <Text style={[styles.rmColumnText, { marginLeft: "auto" }]}>
                RM
              </Text>
            </View>
            <View style={{ flex: 2, padding: 0.5, alignItems: "flex-end" }}>
              <Text style={styles.priceColumnText}>
                {formatCash(data?.commission_sub?.agent_amount)}
              </Text>
            </View>
          </View>
        )}

      {data?.commission_sub?.other_commission &&
        data?.commission_sub?.other_amount && (
          <View style={styles.descriptionContainer}>
            <View style={{ flex: 10, display: "flex", fontSize: 8 }}>
              <Text style={{ paddingLeft: "1px" }}>
                <Text style={{ width: "auto", color: "red" }}>
                  {formatCommission(data?.commission_sub?.other_commission)} %{" "}
                </Text>
                <Text style={{ width: "auto" }}>COMMISSION - OTHERS </Text>
                <Text style={{ width: "auto", color: "red" }}>
                  {data?.commission_sub?.other_bank
                    ? data.commission_sub.other_bank
                    : ""}
                </Text>
                {"  "}
                <Text style={{ width: "auto", color: "red" }}>
                  {data?.commission_sub?.other_bank_account_no
                    ? data.commission_sub.other_bank_account_no
                    : ""}
                </Text>
                {"  "}
                <Text style={{ width: "auto", color: "red" }}>
                  {data?.commission_sub?.other_bank_holder_name
                    ? data.commission_sub.other_bank_holder_name
                    : ""}
                </Text>
              </Text>
            </View>
            <View style={{ flex: 1, padding: 0.5 }}>
              <Text style={[styles.rmColumnText, { marginLeft: "auto" }]}>
                RM
              </Text>
            </View>
            <View style={{ flex: 2, padding: 0.5, alignItems: "flex-end" }}>
              <Text style={styles.priceColumnText}>
                {formatCash(data?.commission_sub?.other_amount)}
              </Text>
            </View>
          </View>
        )} 
         
         */}

      {/* 
      {data?.commission_sub?.insurance_commission && (
        <View style={styles.descriptionContainer}>
          <View style={{ flex: 10, display: "flex", fontSize: 8 }}>
            <Text style={{ paddingLeft: "1px" }}>
              <Text style={{ width: "auto" }}>Insurance Commission</Text>
            </Text>
          </View>
          <View style={{ flex: 1, padding: 0.5 }}>
            <Text style={[styles.rmColumnText, { marginLeft: "auto" }]}>
              RM
            </Text>
          </View>
          <View style={{ flex: 2, padding: 0.5, alignItems: "flex-end" }}>
            <Text style={styles.priceColumnText}>
              {formatCash(data?.commission_sub?.insurance_commission)}
            </Text>
          </View>
        </View>
      )}
       */}

      {/* {showCompanyCommission && data?.car_type !== "used_car" && (
        <View style={styles.descriptionContainer}>
          <View style={{ flex: 10, display: "flex", fontSize: 8 }}>
            <Text style={{ paddingLeft: "1px" }}>
              <Text style={{ width: "auto" }}>COMPANY COMMISSION</Text>
            </Text>
          </View>
          <View style={{ flex: 1, padding: 0.5 }}>
            <Text style={[styles.rmColumnText, { marginLeft: "auto" }]}>
              RM
            </Text>
          </View>
          <View style={{ flex: 2, padding: 0.5, alignItems: "flex-end" }}>
            <Text style={styles.priceColumnText}>100.00</Text>
          </View>
        </View>
      )} */}

      {data?.commission_sub?.deduction_of_lost && (
        <View style={styles.descriptionContainer}>
          <View style={{ flex: 10, padding: 0.5 }}>
            <Text style={[styles.firstContainerText, { paddingLeft: "1px" }]}>
              Deduction of Lost{" "}
              <Text style={{ textTransform: "capitalize" }}>
                ({data?.commission_sub?.from_dealer_or_agent})
              </Text>
            </Text>
            <Text style={styles.firstContainerText}>
              Remark{": "}
              {data?.commission_sub?.remark
                ? data?.commission_sub?.remark
                : "-"}
            </Text>
          </View>
          <View style={{ flex: 1, padding: 0.5 }}>
            <Text style={[styles.rmColumnText, { marginLeft: "auto" }]}>
              RM
            </Text>
          </View>
          <View style={{ flex: 2, padding: 0.5, alignItems: "flex-end" }}>
            <Text style={styles.priceColumnText}>
              {data?.commission_sub?.deduction_of_lost}
            </Text>
          </View>
        </View>
      )}

      {data?.commission_sub?.insurance_dealer_amount && (
        <View style={styles.descriptionContainer}>
          <View style={{ flex: 10, display: "flex", fontSize: 8 }}>
            <Text style={{ paddingLeft: "1px" }}>
              <Text style={{ width: "auto" }}>INSURANCE - DEALER </Text>
              <Text style={{ width: "auto", color: "red" }}>
                {data?.commission_sub?.insurance_dealer_bank
                  ? data.commission_sub.insurance_dealer_bank
                  : ""}
              </Text>
              {"  "}
              <Text style={{ width: "auto", color: "red" }}>
                {data?.commission_sub?.insurance_dealer_bank_account_no
                  ? data.commission_sub.insurance_dealer_bank_account_no
                  : ""}
              </Text>
              {"  "}
              <Text style={{ width: "auto", color: "red" }}>
                {data?.commission_sub?.insurance_dealer_bank_holder_name
                  ? data.commission_sub.insurance_dealer_bank_holder_name
                  : ""}
              </Text>
            </Text>
          </View>
          <View style={{ flex: 1, padding: 0.5 }}>
            <Text style={[styles.rmColumnText, { marginLeft: "auto" }]}>
              RM
            </Text>
          </View>
          <View style={{ flex: 2, padding: 0.5, alignItems: "flex-end" }}>
            <Text style={styles.priceColumnText}>
              {formatCash(data?.commission_sub?.insurance_dealer_amount)}
            </Text>
          </View>
        </View>
      )}

      {data?.commission_sub?.insurance_agent_amount && (
        <View style={styles.descriptionContainer}>
          <View style={{ flex: 10, display: "flex", fontSize: 8 }}>
            <Text style={{ paddingLeft: "1px" }}>
              <Text style={{ width: "auto" }}>INSURANCE - AGENT </Text>
              <Text style={{ width: "auto", color: "red" }}>
                {data?.commission_sub?.insurance_agent_bank
                  ? data.commission_sub.insurance_agent_bank
                  : ""}
              </Text>
              {"  "}
              <Text style={{ width: "auto", color: "red" }}>
                {data?.commission_sub?.insurance_agent_bank_holder_name
                  ? data.commission_sub.insurance_agent_bank_holder_name
                  : ""}
              </Text>
              {"  "}
              <Text style={{ width: "auto", color: "red" }}>
                {data?.commission_sub?.insurance_agent_bank_account_no
                  ? data.commission_sub.insurance_agent_bank_account_no
                  : ""}
              </Text>
            </Text>
          </View>
          <View style={{ flex: 1, padding: 0.5 }}>
            <Text style={[styles.rmColumnText, { marginLeft: "auto" }]}>
              RM
            </Text>
          </View>
          <View style={{ flex: 2, padding: 0.5, alignItems: "flex-end" }}>
            <Text style={styles.priceColumnText}>
              {formatCash(data?.commission_sub?.insurance_agent_amount)}
            </Text>
          </View>
        </View>
      )}

      {data?.commission_sub?.insurance_broker_amount && (
        <View style={styles.descriptionContainer}>
          <View style={{ flex: 10, display: "flex", fontSize: 8 }}>
            <Text style={{ paddingLeft: "1px" }}>
              <Text style={{ width: "auto" }}>INSURANCE - BROKER </Text>
              <Text style={{ width: "auto", color: "red" }}>
                {data?.commission_sub?.insurance_broker_bank
                  ? data.commission_sub.insurance_broker_bank
                  : ""}
              </Text>
              {"  "}
              <Text style={{ width: "auto", color: "red" }}>
                {data?.commission_sub?.insurance_broker_bank_holder_name
                  ? data.commission_sub.insurance_broker_bank_holder_name
                  : ""}
              </Text>
              {"  "}
              <Text style={{ width: "auto", color: "red" }}>
                {data?.commission_sub?.insurance_broker_bank_account_no
                  ? data.commission_sub.insurance_broker_bank_account_no
                  : ""}
              </Text>
            </Text>
          </View>
          <View style={{ flex: 1, padding: 0.5 }}>
            <Text style={[styles.rmColumnText, { marginLeft: "auto" }]}>
              RM
            </Text>
          </View>
          <View style={{ flex: 2, padding: 0.5, alignItems: "flex-end" }}>
            <Text style={styles.priceColumnText}>
              {formatCash(data?.commission_sub?.insurance_broker_amount)}
            </Text>
          </View>
        </View>
      )}

      {/* {data?.sales_quotation?.other_charges[4]?.detail && (
        <View style={styles.descriptionContainer}>
          <View style={{ flex: 10, display: "flex", fontSize: 8 }}>
            <Text style={{ paddingLeft: "1px" }}>
              <Text style={{ width: "auto" }}>Insurance Commission</Text>
              <Text style={{ width: "auto", color: "red" }}>
                {data?.commission_sub?.broker_bank
                  ? data.commission_sub.broker_bank
                  : " "}
              </Text>
              {"  "}
              <Text style={{ width: "auto", color: "red" }}>
                {data?.commission_sub?.broker_bank_account_no
                  ? data.commission_sub.broker_bank_account_no
                  : " "}
              </Text>
            </Text>
          </View>
          <View style={{ flex: 1, padding: 0.5 }}>
            <Text style={[styles.rmColumnText, { marginLeft: "auto" }]}>
              RM
            </Text>
          </View>
          <View style={{ flex: 2, padding: 0.5, alignItems: "flex-end" }}>
            <Text style={styles.priceColumnText}>
              {formatCash(data?.sales_quotation?.other_charges[4].detail)}
            </Text>
          </View>
        </View>
      )} */}

      <View style={styles.descriptionContainer}>
        <div style={{ marginBottom: 15 }} />
      </View>

      {/* <TotalCommission
        totalTitle="total"
        price={calTotalCommission(
          data?.commission_sub?.dealer_amount,
          data?.commission_sub?.broker_amount,
          data?.commission_sub?.agent_amount,
          data?.commission_sub?.deduction_of_lost,
          data?.commission_sub?.insurance_commission,
          data?.commission_sub?.other_amount,
          data?.commission_sub?.insurance_dealer_amount,
          data?.commission_sub?.insurance_broker_amount,
          data?.commission_sub?.insurance_agent_amount
        )}
      /> */}

      <TotalCommission
        totalTitle="total"
        price={calTotalCommission(
          data?.commission_sub?.commissionFields ?? [],
          data?.commission_sub?.deduction_of_lost,
          data?.commission_sub?.insurance_commission,
          data?.commission_sub?.insurance_dealer_amount,
          data?.commission_sub?.insurance_broker_amount,
          data?.commission_sub?.insurance_agent_amount
        )}
      />
    </div>
  );
};

export default CommissionThirdTable;
